import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, TextField, Grid, Typography, FormControlLabel, Checkbox, Box , Tabs, Tab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { SERVER_URL } from '../../constants.js';
import { addStudentStay } from '../apiUtils'; // Import the utility function


import { CAMPUSES } from '../../constants';

function AddStudent(props) {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('single');
  const [student, setStudent] = useState({
    dateAdded: new Date(),
    mtRef: '',
    studentName: '',
    studentSurname: '',
    studentDob: null,
    studentGender: '',
    studentNationality: '',
    englishLevel: '',
    roomRequirements: '',
    classRequirements: '',
    allergies: '',
    notes: '',
    hasAllPermission: false,
    hasPoolPermission: false,
    hasPhotoPermission: false,
    hasMedicalPermission: false,
    hasHospitalPermission: false,
    hasExcursionPermission: false,
    hasActivityPermission: false,
    hasSupervisionPermission: false,
  });

  const [prefix, setPrefix] = useState('');
  const [numberOfStudents, setNumberOfStudents] = useState(1);
  const [groupNationality, setGroupNationality] = useState('');
  const [stay, setStay] = useState({
    campus: '',
    arrivalDate: new Date('2025-06-29'),
    departureDate: new Date('2025-08-10'),
});

  const handleClickOpen = () => {
    const today = new Date();
    setStudent(prevStudent => ({
      ...prevStudent,
      dateAdded: today
    }));
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleDateChange = (date, name) => {
    setStudent(prevStudent => ({
      ...prevStudent,
      [name]: date
    }));
  };

  const handleStayDateChange = (date, name) => {
    setStay(prevStay => ({
      ...prevStay,
      [name]: date
    }));
  };

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;

    if (name === "hasAllPermission") {
      const newPermissions = {
        hasAllPermission: checked,
        hasPoolPermission: checked,
        hasPhotoPermission: checked,
        hasMedicalPermission: checked,
        hasHospitalPermission: checked,
        hasExcursionPermission: checked,
        hasActivityPermission: checked,
        hasSupervisionPermission: checked
      };

      setStudent(prevStudent => ({
        ...prevStudent,
        ...newPermissions
      }));
    } else if (type === "checkbox") {
      setStudent(prevStudent => ({
        ...prevStudent,
        [name]: checked
      }));
    } else {
      setStudent(prevStudent => ({
        ...prevStudent,
        [name]: value
      }));
    }
  };

  const handleStayChange = (event) => {
    const { name, value } = event.target;
    setStay((prevState) => ({
        ...prevState,
        [name]: value,
    }));
};


  const handleSave = () => {
    if (mode === 'multiple') {
      const token = sessionStorage.getItem('bearer');
      // Validate that the prefix is exactly 4 characters
      if (prefix.length !== 4) {
        alert("Please provide a prefix that is exactly 4 characters long.");
        return; // Stop further execution if validation fails
      }
      // Make the API call to fetch the max number for the given prefix
      fetch(`${SERVER_URL}api/prefixCount/${prefix}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch prefix count');
      }
      return response.json();
    })
    .then(maxNumber => {
      //console.log("Max number for prefix:", maxNumber);

      // Generate MT Ref values for each student to be created
      const mtRefs = [];
      for (let i = 0; i < numberOfStudents; i++) {
        const index = maxNumber + 1 + i;
        const formattedIndex = String(index).padStart(4, '0'); // Ensure 4-digit formatting with leading zeros
        const mtRef = `${prefix}${formattedIndex}`;
        mtRefs.push(mtRef);
      }
      //console.log("Generated MT Refs:", mtRefs);

      mtRefs.forEach(mtRef => {
        // Create a new student object for each iteration
        const newStudent = {
          ...student, // Spread operator to copy existing fields
          dateAdded: new Date(),
          mtRef: mtRef,
          studentNationality: groupNationality, // Set the nationality from the group
        };
    
        // Add the new student using props.addStudent
        props.addStudent(newStudent)
          .then(newStudentId => {
            if (newStudentId) {
              console.log("New student added with ID:", newStudentId);
              // Use newStudentId for any further logic if needed
              //console.log("stay", stay)
              addStudentStay(stay, newStudentId )
              props.setRefreshTrigger(prev => prev + 1);
            }
          });
      });
    })
    .catch(error => {
      console.error("Error fetching max number for prefix:", error);
    });
    
    //console.log('doing nothing');
    } else {
      props.addStudent(student)
      .then(newStudentId => {
        if (newStudentId) {
          console.log("New student added with ID:", newStudentId);
          // Use newStudentId for a subsequent call
        }
      });
    }
    handleClose();
  };

  const handleModeChange = (event, newValue) => {
    setMode(newValue);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <AddIcon color="primary" />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Add Student
          <Tabs value={mode} onChange={handleModeChange}>
            <Tab value="single" label="Add Student" />
            <Tab value="multiple" label="Add Group" />
          </Tabs>
        </DialogTitle>
        <DialogContent sx={{ minWidth: 600, minHeight: 600 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 2 }}>
              {mode === 'multiple' && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      label="Prefix"
                      value={prefix}
                      onChange={(e) => setPrefix(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Number of Students"
                      type="number"
                      value={numberOfStudents}
                      onChange={(e) => setNumberOfStudents(Number(e.target.value))}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Nationality"
                      value={groupNationality}
                      onChange={(e) => setGroupNationality(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ width: '100%', borderTop: '1px solid #e0e0e0', my: 2 }} />
                  </Grid>
                  <Grid item xs={6}>
                  <DatePicker
                            label="Arrive"
                            value={stay.arrivalDate}
                            onChange={(date) => handleStayDateChange(date, 'arrivalDate')}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                sx={{ mb: 0, mt: 0 }}
                            />
                            )}
                        />
                        </Grid>
                        <Grid item xs={6}>
                        <DatePicker
                            label="Depart"
                            value={stay.departureDate}
                            onChange={(date) => handleStayDateChange(date, 'departureDate')}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                sx={{ mb: 0, mt: 0 }}
                            />
                            
                            )}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="campus-label">Campus</InputLabel>
                            <Select
                                labelId="campus-label"
                                id="campus"
                                name="campus"
                                value={stay.campus}
                                onChange={handleStayChange}
                                label="Campus"
                            >
                                {CAMPUSES.map((campus) => (
                                    <MenuItem key={campus.value} value={campus.value}>
                                        {campus.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Grid>
                </>
              )}
              {mode === 'single' && (
                <>
                  <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <DatePicker
                      label="Date Added"
                      value={student.dateAdded}
                      onChange={(date) => handleDateChange(date, 'dateAdded')}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth sx={{ mb: 0, mt: 0 }} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="Master Tracker ID"
                      value={student.mtRef || ''}
                      onChange={handleChange}
                      fullWidth
                      name="mtRef"
                      sx={{ mb: 0, mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="Name"
                      value={student.studentName || ''}
                      onChange={handleChange}
                      fullWidth
                      name="studentName"
                      sx={{ mb: 0, mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="Surname"
                      value={student.studentSurname || ''}
                      onChange={handleChange}
                      fullWidth
                      name="studentSurname"
                      sx={{ mb: 0, mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <DatePicker
                      label="Student Date Of Birth"
                      value={student.studentDob}
                      onChange={(date) => handleDateChange(date, 'studentDob')}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth sx={{ mb: 0, mt: 0 }} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="Gender"
                      value={student.studentGender || ''}
                      onChange={handleChange}
                      fullWidth
                      name="studentGender"
                      sx={{ mb: 0, mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="Nationality"
                      value={student.studentNationality || ''}
                      onChange={handleChange}
                      fullWidth
                      name="studentNationality"
                      sx={{ mb: 0, mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="English Level"
                      value={student.englishLevel || ''}
                      onChange={handleChange}
                      fullWidth
                      name="englishLevel"
                      sx={{ mb: 0, mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="Room Requirements"
                      value={student.roomRequirements || ''}
                      onChange={handleChange}
                      fullWidth
                      name="roomRequirements"
                      sx={{ mb: 0, mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="Class Requirements"
                      value={student.classRequirements || ''}
                      onChange={handleChange}
                      fullWidth
                      name="classRequirements"
                      sx={{ mb: 0, mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="Allergies"
                      value={student.allergies || ''}
                      onChange={handleChange}
                      fullWidth
                      name="allergies"
                      sx={{ mb: 0, mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="Notes"
                      value={student.notes || ''}
                      onChange={handleChange}
                      fullWidth
                      name="notes"
                      sx={{ mb: 0, mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ width: '100%', borderTop: '1px solid #e0e0e0', my: 2 }} />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography sx={{ mb: 0, mt: 0 }}>
                      Permissions
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={student.hasAllPermission}
                          onChange={handleChange}
                          name="hasAllPermission"
                        />
                      }
                      label="All"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={student.hasPoolPermission}
                          onChange={handleChange}
                          name="hasPoolPermission"
                        />
                      }
                      label="Swimming Pool"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={student.hasPhotoPermission}
                          onChange={handleChange}
                          name="hasPhotoPermission"
                        />
                      }
                      label="Photography"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={student.hasMedicalPermission}
                          onChange={handleChange}
                          name="hasMedicalPermission"
                        />
                      }
                      label="Medical"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={student.hasHospitalPermission}
                          onChange={handleChange}
                          name="hasHospitalPermission"
                        />
                      }
                      label="Hospital"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={student.hasExcursionPermission}
                          onChange={handleChange}
                          name="hasExcursionPermission"
                        />
                      }
                      label="Excursions"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={student.hasActivityPermission}
                          onChange={handleChange}
                          name="hasActivityPermission"
                        />
                      }
                      label="Activity"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={student.hasSupervisionPermission}
                          onChange={handleChange}
                          name="hasSupervisionPermission"
                        />
                      }
                      label="Supervision"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
  }

export default AddStudent;
