import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Grid from "@mui/system/Unstable_Grid";
import {lightBlue} from "@mui/material/colors";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import * as React from "react";

export default function StudentsIncompleteBookings({ mData, handleSelectStudent }) {

    //console.log("missing data list: " + mData);

    return (
        <TableContainer component={Paper} sx={{margin: '0 auto', maxWidth: 1900}}>
            <Grid sx={{display: 'inline', alignContent: 'flex-start', position: 'left', bgcolor: lightBlue[100]}}>
            </Grid>

            <Table sx={{minWidth: 800, color: lightBlue[200], margin: '0 auto'}} size="small" aria-label="simple table">
                <TableHead>
                    <TableRow sx={{bgcolor: lightBlue[100], border: 1.5}}>
                        <TableCell sx={{border: 1.5}} align="center">Student ID</TableCell>
                        <TableCell sx={{border: 1.5}} align="center">mtRef</TableCell>
                        <TableCell sx={{border: 1.5}} align="center">Name</TableCell>
                        <TableCell sx={{border: 1.5}} align="center">Surname</TableCell>
                        <TableCell sx={{border: 1.5}} align="center">Nationality</TableCell>
                        <TableCell sx={{border: 1.5}} align="center">Campus</TableCell>
                        <TableCell sx={{border: 1.5}} align="center">Stay Start</TableCell>
                        <TableCell sx={{border: 1.5}} align="center">Stay End</TableCell>
                        <TableCell sx={{border: 1.5}} align="center">Stay Duration</TableCell>
                        <TableCell sx={{border: 1.5}} align="center">Nights Booked</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mData.map((item, index) => (
                        <React.Fragment key={index}>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        border: 1.5,
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        color: 'blue',
                                        textDecoration: 'underline',
                                      }}
                                    onClick={() => handleSelectStudent(item.studentId)}
                                    >
                                    {item.studentId}
                                </TableCell>
                                <TableCell sx={{border: 1.5, textAlign: 'center'}}>{item.mt_ref}</TableCell>
                                <TableCell sx={{border: 1.5, textAlign: 'center'}}>{item.student_name}</TableCell>
                                <TableCell sx={{border: 1.5, textAlign: 'center'}}>{item.student_surname}</TableCell>
                                <TableCell sx={{border: 1.5, textAlign: 'center'}}>{item.student_nationality}</TableCell>
                                <TableCell sx={{border: 1.5, textAlign: 'center'}}>{item.campus}</TableCell>
                                <TableCell sx={{border: 1.5, textAlign: 'center'}}>{item.stayStart}</TableCell>
                                <TableCell sx={{border: 1.5, textAlign: 'center'}}>{item.stayEnd}</TableCell>
                                <TableCell sx={{border: 1.5, textAlign: 'center'}}>{item.stayDuration}</TableCell>
                                <TableCell sx={{border: 1.5, textAlign: 'center'}}>{item.bookingCoverage}</TableCell>
                            </TableRow>
                        </React.Fragment>))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}