import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {lightBlue} from "@mui/material/colors";
import Grid from '@mui/system/Unstable_Grid';




export default function MissingFlightsTable(props) {
    return (
        <TableContainer component={Paper} sx={{margin: '0 auto',  maxWidth: 1900}}>
            <Grid sx={{ display: 'inline', alignContent: 'flex-start', position:'left', bgcolor:lightBlue[100]}}>
            </Grid>

            <Table sx={{minWidth: 800, color:lightBlue[200], margin: '0 auto'}} size="small" aria-label="simple table">
                <TableHead>
                    <TableRow sx={{bgcolor:lightBlue[100], border:1.5}}>
                        <TableCell sx={{border:1.5}} align="center" >Student ID</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Arrival Date</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Student Name</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Student Surname</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Transfer ID</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Departure</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Arrival</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Flight ID</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.mFlights.map((item, index) => (
                            <React.Fragment key={index}>
                                <TableRow>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{item[0]}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{item[1]}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{item[2]}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{item[3]}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{item[4]}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{item[5]}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{item[6]}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{item[7]}</TableCell>
                                </TableRow>
                            </React.Fragment>))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

