import React, {useEffect, useState, useCallback} from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import FlightTable from './FlightTable.js';
import {SERVER_URL} from "../constants";
import { spacing } from '@mui/system';
import MissingFlightsTable from "./MissingFlightsTable";
import StudentsMissingStayData from "./StudentsMissingStayData.js";
import StudentsIncompleteBookings from "./StudentsIncompleteBookings.js";
import './WhoIsDoing.css';
import dayjs, { Dayjs } from 'dayjs';
import Scroll from './Scroll.js';
import {CssBaseline} from "@mui/material";
import StudentsIncompleteActivities from './StudentsIncompleteActivities.js';

export default function FlightsAndStudentData({ handleSelectStudent, yearSelected, refreshTrigger }) {
    const [value, setValue] = React.useState('1');
    const [flights, setFlights] = useState([]);
    const [missingFlights, setMissingFlights] = useState([]);
    const [studentsMissingData, setStudentsMissingData] = useState([]);
    const [lastUpdate, setLastUpdate] = useState("");
    const [incompleteBookings, setIncompleteBookings] = useState([]);
    const [incompleteActivities, setIncompleteActivities] = useState([]);
  

    const fetchIncompleteBookings = useCallback(() => {
        console.log("triggered fetch incomplete bookings: " + yearSelected);
        console.log("triggered fetch incomplete bookings: " + refreshTrigger);
        const token = sessionStorage.getItem('bearer');
        fetch(`${SERVER_URL}api/IncompleteBookingsYear/0/${yearSelected}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((response) => {
            if (response.status === 204) {
              return [];
            } else if (response.ok) {
              return response.json();
            } else {
              throw new Error('Failed to fetch incomplete bookings');
            }
          })
          .then((data) => {
            setIncompleteBookings(data.length > 0 ? [...data] : []); // Ensure a new array reference
          })
          .catch((err) => console.error(err));
      }, [yearSelected, refreshTrigger]); // Add any dependencies needed

      const fetchIncompleteActivities = useCallback(() => {
        console.log("triggered fetch incomplete activities: " + yearSelected)
        console.log("triggered fetch incomplete bookings: " + refreshTrigger);
        
        const token = sessionStorage.getItem('bearer');
        fetch(`${SERVER_URL}api/IncompleteBookingsYear/1/${yearSelected}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.status === 204) {
            return [];
          } else if (response.ok) {
            return response.json();
          } else {
            throw new Error('Failed to fetch incomplete activities');
          }
        })
        .then((data) => {
          //console.log("Fetched incomplete activities:", data);
          setIncompleteActivities(data.length > 0 ? [...data] : []); // Ensure a new array reference
        })
        .catch((err) => console.error(err));
      }, [yearSelected, refreshTrigger]);
      
      useEffect(() => {
        console.log(`Refresh Triggered: ${yearSelected} or ${refreshTrigger}`);
        fetchIncompleteBookings();
        fetchIncompleteActivities();
      }, [yearSelected, refreshTrigger, fetchIncompleteBookings, fetchIncompleteActivities]);
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchFlights = (updateExisting = false) => {
        console.log("Test - fetching flights")
        const token = sessionStorage.getItem('bearer');
        fetch(`${SERVER_URL}api/flights/flight?updateExisting=${updateExisting}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                if (response.status === 204) {
                    return [];
                } else if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to fetch flights');
                }
            })
            .then((data) => {
                sessionStorage.setItem('flights', JSON.stringify(data));
                setFlights(data);
                console.log("Outputting Flights")
                console.log(flights)
            })
            .catch((err) => console.error(err));
    };

    useEffect(() => {
        console.log("Flights state updated:", flights);
    }, [flights]);

    useEffect(() => {
        fetchFlights();
    }, []);

    useEffect(()=>{
        const  interval=setInterval(()=>{
            fetchFlights(true)
            setLastUpdate(dayjs().format('L LT'))
            console.log(lastUpdate)
        },3600000)
        return()=>clearInterval(interval);
    },[])


const update = (flightNumber, sdata) => {
const flightsUpdate = {...flights}
    flightsUpdate[flightNumber] = sdata[flightNumber]
    setFlights(flightsUpdate)
    console.log(flightsUpdate)
}



    const fetchSingleFlight = (flightNo) => {
        console.log("Test" + flightNo)
        const fl = flights[flightNo]
        console.log(fl)

        const token = sessionStorage.getItem('bearer');
        fetch(`${SERVER_URL}api/flights/singleFlight?flightID=${flightNo}&flightDate=${fl.flightDate}`, {
            headers: {Authorization: `Bearer ${token}`},
        })
            .then((response) => {
                if (response.status === 204) {
                    return [];
                } else if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to fetch flights');
                }
            })
            .then((sdata) => {
                sessionStorage.setItem('singleFlight', JSON.stringify(sdata));
                update(flightNo, sdata)
            })
            .catch((err) => console.error(err));
     }


    const fetchMissingFlights = () => {
        const token = sessionStorage.getItem('bearer');
        fetch(`${SERVER_URL}api/flights/missingFlights`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                if (response.status === 204) {
                    return [];
                } else if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to fetch missing flights');
                }
            })
            .then((data) => {
                sessionStorage.setItem('missingFlights', JSON.stringify(data));
                console.log(data)
                setMissingFlights(data);
            })
            .catch((err) => console.error(err));
    };

    useEffect(() => {
        fetchMissingFlights();
    }, []);

    const fetchStudentsMissingData = () => {
        const token = sessionStorage.getItem('bearer');
        fetch(`${SERVER_URL}api/studentsWithoutStays`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                if (response.status === 204) {
                    return [];
                } else if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to fetch Students Missing Data');
                }
            })
            .then((data) => {
                sessionStorage.setItem('studentsMissingData', JSON.stringify(data));
                console.log(data)
                setStudentsMissingData(data);
            })
            .catch((err) => console.error(err));
    };


    useEffect(() => {
        fetchStudentsMissingData();
    }, []);



    return (
             <Box   sx={{ width: '100%', typography: 'body1', justifyContent: 'center', alignItems: 'center'}}>
            <TabContext sx={{ width: '100%', typography: 'body1', justifyContent: 'center', alignItems: 'center'}}value={value}>
                <Box display="flex" justifyContent="center" width="100%" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList  variant="scrollable"
                              scrollButtons="auto"
                              allowScrollButtonsMobile onChange={handleChange} aria-label="Tabs" centered  >
                        <Tab label="Students Without Stay Data" value="1" sx={{ flexGrow: 0.5, justifyContent: 'center', border:1, borderRadius: 1, mr:0.5 }} />
                        <Tab label="Students With Incomplete Bookings" value="2" sx={{ flexGrow: 0.5, justifyContent: 'center', border:1, borderRadius: 1, mr:0.5 }} />
                        <Tab label="Students With Incomplete Activities" value="3" sx={{ flexGrow: 0.5, justifyContent: 'center', border:1, borderRadius: 1, mr:0.5 }} />
                        <Tab  label="Students Arriving Today" value="4" sx={{ flexGrow: 0.5, justifyContent: 'center', border:1, borderRadius: 1, mr:0.5 }} />
                        <Tab label="Students Missing Flight Data" value="5" sx={{ flexGrow: 0.5, justifyContent: 'center', border:1, borderRadius: 1, mr:0.5 }} />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <StudentsMissingStayData mData={studentsMissingData} handleSelectStudent={handleSelectStudent} />
                </TabPanel>
                <TabPanel value="2">
                    <StudentsIncompleteBookings mData={incompleteBookings} handleSelectStudent={handleSelectStudent} />
                </TabPanel>
                <TabPanel value="3">
                    <StudentsIncompleteActivities mData={incompleteActivities} handleSelectStudent={handleSelectStudent} />
                </TabPanel>
                <TabPanel sx={{ fontWeight:10000, fontSize: 'h6.fontSize', letterSpacing: 2, mb:10}} value="4">
                   <FlightTable jsonFlightData = {flights} noFlightData={true} fetchFlights={fetchFlights} ls={lastUpdate} fetchSingle={fetchSingleFlight} ></FlightTable>
               </TabPanel>
                <TabPanel value="5"><MissingFlightsTable mFlights = {missingFlights} ></MissingFlightsTable></TabPanel>
            </TabContext>
        </Box>
    );
}


