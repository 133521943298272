import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Grid from "@mui/system/Unstable_Grid";
import {lightBlue} from "@mui/material/colors";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import * as React from "react";

export default function StudentsMissingStayData({ mData, handleSelectStudent }) {

    //console.log("missing data list: " + mData);

    return (
        <TableContainer component={Paper} sx={{margin: '0 auto', maxWidth: 1900}}>
            <Grid sx={{display: 'inline', alignContent: 'flex-start', position: 'left', bgcolor: lightBlue[100]}}>
            </Grid>

            <Table sx={{minWidth: 800, color: lightBlue[200], margin: '0 auto'}} size="small" aria-label="simple table">
                <TableHead>
                    <TableRow sx={{bgcolor: lightBlue[100], border: 1.5}}>
                        <TableCell sx={{border: 1.5}} align="center">Student ID</TableCell>
                        <TableCell sx={{border: 1.5}} align="center">Reference</TableCell>
                        <TableCell sx={{border: 1.5}} align="center">Name</TableCell>
                        <TableCell sx={{border: 1.5}} align="center">Surname</TableCell>
                        <TableCell sx={{border: 1.5}} align="center">Gender</TableCell>
                        <TableCell sx={{border: 1.5}} align="center">Nationality</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mData.map((item, index) => (
                        <React.Fragment key={index}>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        border: 1.5,
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        color: 'blue',
                                        textDecoration: 'underline',
                                      }}
                                    onClick={() => handleSelectStudent(item.id)}
                                    >
                                    {item.id}
                                </TableCell>
                                <TableCell sx={{border: 1.5, textAlign: 'center'}}>{item.mtRef}</TableCell>
                                <TableCell sx={{border: 1.5, textAlign: 'center'}}>{item.studentName}</TableCell>
                                <TableCell sx={{border: 1.5, textAlign: 'center'}}>{item.studentSurname}</TableCell>
                                <TableCell sx={{border: 1.5, textAlign: 'center'}}>{item.studentGender}</TableCell>
                                <TableCell sx={{border: 1.5, textAlign: 'center'}}>{item.studentNationality}</TableCell>
                            </TableRow>
                        </React.Fragment>))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}