// apiUtils.js
import { SERVER_URL } from '../constants.js'


export const addStudentStay = (stay, studentId, fetchStaysCallback) => {
    const token = sessionStorage.getItem("bearer");
  
    return fetch(`${SERVER_URL}api/student/${studentId}/stay`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(stay)
    })
      .then(response => {
        if (response.ok) {
          if (fetchStaysCallback) {
            fetchStaysCallback(); // Refresh stays list if a callback is provided
          }
          return response.json(); // Return the response if needed for further processing
        } else {
          throw new Error('Failed to add stay');
        }
      })
      .catch(err => {
        console.error('Error adding stay:', err);
        throw err; // Re-throw the error if you want to handle it outside
      });
  };
  