import React, { useState } from 'react';
import './App.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Login from './components/Login';
import StudentSearch from './components/StudentSearch';
import WhoIsDoing from './components/WhoIsDoing';
import Transfers from './components/Transfers';
import { SERVER_URL } from './constants.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import Rent from './components/Rent';
import Classrooms from './components/Classrooms.js';
import Bedrooms from './components/Bedrooms.js';
import Planner from './components/Planner.js'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Weekly from './components/WeeklyLists.js'
import Activities from './components/Activities.js'
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem as SelectMenuItem } from '@mui/material';

function App() {
  const [isAuthenticated, setAuth] = useState(false);
  const [showWhoIsDoing, setShowWhoIsDoing] = useState(false);
  const [showPlanner, setShowPlanner] = useState(false);
  const [showFinancials, setShowFinancials] = useState(false);
  const [showRentPage, setShowRentPage] = useState(false);
  const [showTransfers, setShowTransfers] = useState(false);
  const [showClassrooms, setShowClassrooms] = useState(false);
  const [showBedrooms, setShowBedrooms] = useState(false);
  const [showActivities, setShowActivities] = useState(false);
  const [username, setUsername] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [showWeekly, setShowWeekly] = useState(false);
  const [selectedYear, setSelectedYear] = useState("2025");

  const onLoginSuccess = (username, role) => {
    setUsername(username);
    setShowFinancials(role === 'ADMIN');
    setAuth(true);
    console.log("Logged in user:", username); // Now this should log the correct username
  };
  
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    const token = sessionStorage.getItem('bearer');
    fetch(`${SERVER_URL}api/v1/auth/logout`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((err) => console.error(err));

    sessionStorage.removeItem('isAuthenticated');
    setAuth(false);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
      };
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
      <div className="App">
        <title>GREATERBASE</title>
        <AppBar position="static">
          <Toolbar>
            {isAuthenticated && (
              <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="year-select-label">Year</InputLabel>
                  <Select
                    labelId="year-select-label"
                    id="year-select"
                    value={selectedYear}
                    label="Year"
                    onChange={handleYearChange}
                  >
                    <SelectMenuItem value={"2023"}>2023</SelectMenuItem>
                    <SelectMenuItem value={"2024"}>2024</SelectMenuItem>
                    <SelectMenuItem value={"2025"}>2025</SelectMenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
            {isAuthenticated && (
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  color="inherit"
                  onClick={() => {
                    setShowWhoIsDoing(false);
                    setShowRentPage(false);
                    setShowTransfers(false);
                    setShowClassrooms(false);
                    setShowBedrooms(false);
                    setShowActivities(false);
                    setShowPlanner(false)
                    setShowWeekly(false);
                  }}
                >
                  Students
                </Button>
                <Button
                  color="inherit"
                  onClick={() => {
                    setShowWhoIsDoing(false);
                    setShowRentPage(false);
                    setShowTransfers(false);
                    setShowClassrooms(false);
                    setShowBedrooms(false);
                    setShowActivities(false);
                    setShowPlanner(false)
                    setShowWeekly(true);
                  }}
                >
                  Weekly
                </Button>
                <Button
                  color="inherit"
                  onClick={handleMenuClick}
                >
                  Groups
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => {
                    setShowWhoIsDoing(false);
                    setShowRentPage(false);
                    setShowTransfers(false);
                    setShowClassrooms(true);
                    setShowBedrooms(false);
                    setShowActivities(false);
                    setShowPlanner(false);
                    setShowWeekly(false);
                    handleClose();
                  }}>Classrooms</MenuItem>
                  <MenuItem onClick={() => {
                    setShowWhoIsDoing(false);
                    setShowRentPage(false);
                    setShowTransfers(false);
                    setShowClassrooms(false);
                    setShowBedrooms(false);
                    setShowActivities(true);
                    setShowPlanner(false);
                    setShowWeekly(false);
                    handleClose();
                  }}>Activities</MenuItem>
                  <MenuItem onClick={() => {
                    setShowWhoIsDoing(false);
                    setShowRentPage(false);
                    setShowTransfers(false);
                    setShowClassrooms(false);
                    setShowBedrooms(true);
                    setShowActivities(false);
                    setShowPlanner(false);
                    setShowWeekly(false);
                    handleClose();
                  }}>Bedrooms</MenuItem>
                </Menu>
                <Button
                  color="inherit"
                  onClick={() => {
                    setShowWhoIsDoing(true);
                    setShowRentPage(false);
                    setShowTransfers(false);
                    setShowClassrooms(false);
                    setShowBedrooms(false);
                    setShowActivities(false);
                    setShowPlanner(false);
                    setShowWeekly(false);
                  }}
                >
                  Planner
                </Button>
                <Button
                  color="inherit"
                  onClick={() => {
                    setShowWhoIsDoing(false);
                    setShowRentPage(false);
                    setShowTransfers(false);
                    setShowClassrooms(false);
                    setShowBedrooms(false);
                    setShowActivities(false);
                    setShowPlanner(true);
                    setShowWeekly(false);
                  }}
                >
                  Scheduler
                </Button>
                <Button
                  color="inherit"
                  onClick={() => {
                    setShowWhoIsDoing(false);
                    setShowRentPage(false);
                    setShowTransfers(true);
                    setShowClassrooms(false);
                    setShowBedrooms(false);
                    setShowActivities(false);
                    setShowPlanner(false);
                    setShowWeekly(false);
                  }}
                >
                  Transfers
                </Button>
                {showFinancials && (
                  <Button
                    color="inherit"
                    onClick={() => {
                      setShowWhoIsDoing(false);
                      setShowRentPage(true);
                      setShowTransfers(false);
                      setShowClassrooms(false);
                      setShowBedrooms(false);
                      setShowActivities(false);
                      setShowPlanner(false);
                      setShowWeekly(false);
                    }}
                  >
                    Costs
                  </Button>
                )}
                <Button color="inherit" onClick={handleLogout}>
                  Logout
                </Button>
              </Box>
            )}
          </Toolbar>
        </AppBar>
        {isAuthenticated ? (
          showWhoIsDoing ? (
            <WhoIsDoing />
          ) : showRentPage ? (
            <Rent />
          ) : showTransfers ? (
            <Transfers />
          ) : showClassrooms ? (
            <Classrooms />
          ) : showWeekly ? (
            <Weekly />
          ) : showBedrooms ? (
            <Bedrooms />
          ) : showActivities ? (
            <Activities />
          ): showPlanner ? (
            <Planner />
          ) : (
            <StudentSearch showFinancials={showFinancials} selectedYear={selectedYear} onYearChange={handleYearChange} />
          )
        ) : (
          <Login onLoginSuccess={onLoginSuccess} />
        )}
      </div>
    </LocalizationProvider>
  );
}

export default App;
