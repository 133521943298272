import * as React from 'react';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from "@mui/material/IconButton";
import Typography from '@mui/material/Typography';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import {lightBlue} from "@mui/material/colors";
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import Button from '@mui/material/Button';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import {useEffect} from "react";
import Grid from '@mui/system/Unstable_Grid';

export default function FlightTable(props) {
    const [open, setOpen] = React.useState(false);
    const [sortedFlights, setSortedFlights] = React.useState([]);



    const handleRowClick = (flightNumber) => {
        setOpen((prevOpen) => ({
            ...prevOpen, [flightNumber]: !prevOpen[flightNumber]
        }));
    };

    function formatDateTime(dtVal) {
        if(dtVal){
        let formattedDT = dtVal.substring(11, 19);
        return formattedDT;
    }

    return '';
 }

 const sortFlights = () => {

  let sorted = Object.values(props.jsonFlightData).sort((a,b) => Date.parse(a.estimatedArrival) - Date.parse(b.estimatedArrival))
     console.log("SortedFlights in sortFlights method")
     console.log(sorted)
     setSortedFlights(sorted)
 }
     useEffect(() => {
         sortFlights()
    }, [props.jsonFlightData]);

    return (
        <TableContainer component={Paper} sx={{margin: '0 auto',  maxWidth: 1900}} >
                  <Grid sx={{ display: 'inline', alignContent: 'flex-start', position:'left', bgcolor:lightBlue[100]}}>
                <Button xs={1} onClick={() => props.fetchFlights(true)} sx={{mb:1.5}} align="right" variant="contained" startIcon={<AirplanemodeActiveIcon />}>
                    Refresh All Flights
                </Button>
            </Grid>
            <Table sx={{minWidth: 800, color:lightBlue[200], margin: '0 auto'}} size="small" aria-label="simple table" >
                <TableHead>
                    <TableRow sx={{bgcolor:lightBlue[100], border:1.5}}>
                        <TableCell sx={{border:1.5}}>Expand</TableCell>
                        <TableCell sx={{border:1.5}} align="center" >Flight number</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Flight Date</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Flight Status</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Arrival Airport</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Arrival Delay</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Scheduled Arrival</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Estimated Arrival</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Actual Arrival</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Departure Delay</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Estimated Departure</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Actual Departure</TableCell>
                        <TableCell sx={{border:1.5}} align="center">No. Students</TableCell>
                        <TableCell sx={{border:1.5}} align="center">Last Update Req.</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(sortedFlights).map(fKey => {
                          const fn = sortedFlights[fKey];
                            return (
                            <React.Fragment key={fn.id}>
                                <TableRow key ={fn.flightNumber}><TableCell>
                                        <IconButton aria-label="expand row" size="large"
                                                    onClick={() => handleRowClick(fn.flightNumber)}> {open[fn.flightNumber] ? <KeyboardArrowUpIcon/> :
                                            <KeyboardArrowDownIcon/>}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center' }}>
                                        <Button  xs={0.5} onClick={() => props.fetchSingle(fn.flightNumber)} sx={{mb:0.5, textAlign: 'right', maxWidth:100, minWidth:100 }}align="centre" variant="contained">
                                            {<FlightLandIcon fontSize='small'  align="left"/>}
                                             {fn.flightNumber}</Button></TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{fn.flightDate}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{fn.flightStatus}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{fn.arrivalAirport}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{fn.arrivalDelay}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{formatDateTime(fn.ScheduledArrival)}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{formatDateTime(fn.estimatedArrival)}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{formatDateTime(fn.actualArrival)}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{fn.departureDelay}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{formatDateTime(fn.estimatedDeparture)}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{formatDateTime(fn.actualDeparture)}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{fn.totalStudentsOnFlight}</TableCell>
                                    <TableCell sx={{border:1.5, textAlign: 'center'}}>{formatDateTime(fn.lastUpdated)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell  style={{paddingBottom: 0, paddingTop: 0}} colSpan={13}>
                                        <Collapse sx={{margin: 1}} in={open[fn.flightNumber]} timeout="auto" unmountOnExit>
                                            <Box sx={{margin: 1, color:lightBlue[800]}}>
                                                <Typography variant="h6" gutterBottom component="div">
                                                    Students On Flight {fn.flightNumber}.
                                                    Driver: {fn.driverName}. Contact: 0{fn.driverNumber}
                                                </Typography>
                                                <Table size="small"  sx={{ border: 0.5, }} aria-label="simple table">
                                                    <TableHead  sx={{ border: 0.5, bgcolor:lightBlue["A100"]}}>
                                                        <TableRow  key={fn.id}  sx={{ border: 0.5}}>
                                                            <TableCell sx={{border:0.5, textAlign: 'center'}}>Student ID</TableCell>
                                                            <TableCell sx={{border:0.5, textAlign: 'center'}}>First Name</TableCell>
                                                            <TableCell sx={{border:0.5, textAlign: 'center'}}>Surname</TableCell>
                                                            <TableCell sx={{border:0.5, textAlign: 'center'}}>School</TableCell>
                                                            <TableCell sx={{border:0.5, textAlign: 'center'}}>DOB</TableCell>
                                                            <TableCell sx={{border:0.5, textAlign: 'center'}}>Arrival Date</TableCell>
                                                            <TableCell sx={{border:0.5, textAlign: 'center'}}>Departure Date</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody >
                                                        {fn.students.map((sd) => (
                                                            <TableRow key={sd.id} component="tr" scope="row" sx={{minWidth: 800, bgcolor:lightBlue[50]}} >
                                                                <TableCell sx={{border:0.5, textAlign: 'center', }}> {sd.id} </TableCell>
                                                                <TableCell sx={{border:0.5, textAlign: 'center'}}> {sd.studentName} </TableCell>
                                                                <TableCell sx={{border:0.5, textAlign: 'center'}}> {sd.studentSurname} </TableCell>
                                                                <TableCell sx={{border:0.5, textAlign: 'center'}}> {sd.attendingSchool} </TableCell>
                                                                <TableCell sx={{border:0.5, textAlign: 'center'}}> {sd.studentDob} </TableCell>
                                                                <TableCell sx={{border:0.5, textAlign: 'center'}}> {sd.arrivalDate} </TableCell>
                                                                <TableCell sx={{border:0.5, textAlign: 'center'}}> {sd.departureDate} </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

