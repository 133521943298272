import React, { useState, useEffect, useCallback } from 'react';
import { SERVER_URL } from '../constants.js';
import MyTabs from './TabComponent/MyTabs.js';
import SearchList from './SearchList.js';
import Scroll from './Scroll.js';
import AddStudent from './AddsEdits/AddStudent.js';
import FlightsAndStudentData from './FlightsAndStudentData.js';

function StudentSearch(props) {
  const [searchField, setSearchField] = useState("");
  const [searchShow, setSearchShow] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [students, setStudents] = useState([]);
  const [yearSelected] = useState([props.selectedYear]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);


  const filteredPersons = students.filter(
    (person) =>
      person.studentName?.toLowerCase().includes(searchField.toLowerCase()) ||
      person.studentSurname?.toLowerCase().includes(searchField.toLowerCase()) ||
      person.mtRef?.toLowerCase().includes(searchField.toLowerCase()) ||
      person.studentNationality?.toLowerCase().includes(searchField.toLowerCase()) ||
      person.id.toString().includes(searchField.toLowerCase())
  );

  const handleCardClick = (person) => {
    setSelectedPerson(person);
    setSearchShow(false);
  };

  const handleSelectStudent = (studentId) => {
    const student = students.find((s) => s.id === studentId);
    if (student) {
      setSelectedPerson(student);
      setSearchShow(false);
    }
  };

  const handleCloseTabs = () => {
    setSelectedPerson(null);
    setSearchField("");
    fetchData();
    setRefreshTrigger(prev => prev + 1); // Increment to trigger a refresh
  };

  const fetchData = () => {
    console.log("triggered fetch data: " + yearSelected);
    fetchStudents();
    };

  const searchList = () => {
    if (searchShow) {
      return (
        <Scroll>
          <SearchList
            filteredPersons={filteredPersons}
            handleCardClick={handleCardClick}
            maxCards={80}
          />
        </Scroll>
      );
    }
  };
  
  const fetchStudents = useCallback(() => {
    setStudents([]); // Clear the existing students data first
    const token = sessionStorage.getItem('bearer');
    fetch(`${SERVER_URL}api/studentsBasicYear/${props.selectedYear}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.status === 204) {
          return [];
        } else if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch students');
        }
      })
      .then((data) => {
        sessionStorage.setItem('students', JSON.stringify(data));
        setStudents(data);
      })
      .catch((err) => console.error(err));
  }, [props.selectedYear]);

  useEffect(() => {
    console.log("triggered fetch students: " + props.selectedYear);
    fetchStudents();
  }, [props.selectedYear, fetchStudents]);

  //useEffect(() => {
    //console.log("Students List:", students);
    //console.log("Filtered Persons List:", filteredPersons);
  //}, [students, filteredPersons]);
  

  const handleChange = (e) => {
    setSearchField(e.target.value);
    setSearchShow(e.target.value !== "");
  };

  const handleReset = () => {
    setSearchField("");
    setSearchShow(false);
  };

  const showTabs = () => {
    if (selectedPerson !== null) {
      return <MyTabs selectedPerson={selectedPerson} onClose={handleCloseTabs} showFinancials={props.showFinancials} />;
    }
    return null;
  };

  const addStudent = (student) => {
    const token = sessionStorage.getItem("bearer");
    return fetch(`${SERVER_URL}api/students`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(student)
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        alert('Something went wrong!');
        return null;
      }
    })
    .then(newStudent => {
      if (newStudent) {
        fetchData();
        setRefreshTrigger(prev => prev + 1); // Increment to trigger a refresh
        return newStudent.id;
      }
    })
    .catch(err => {
      console.error(err);
      return null; // Ensure the function always returns something
    });
  };

  return (
    <section className="garamond">
      <form onReset={handleReset}>
        <div className="pa2">
          <input
            className="pa3 bb br3 grow b--none bg-lightest-blue ma3"
            type="search"
            placeholder="Search by Name, Nationality, or Master Tracker ID..."
            style={{ width: "50%" }}
            value={searchField}
            onChange={handleChange}
          />
        </div>
        {searchList()}
        {showTabs()}
        <div>
          <AddStudent addStudent={addStudent} setRefreshTrigger={setRefreshTrigger}/>
          <p style={{ color: '#999999', fontSize: '10px' }}>
            Is authenticated: {sessionStorage.getItem('isAuthenticated').toString()}
          </p>
        </div>
       </form>
       <div>
          <FlightsAndStudentData handleSelectStudent={handleSelectStudent} yearSelected={props.selectedYear} refreshTrigger={refreshTrigger}/>
        </div>
    </section>
  );
}

export default StudentSearch;
